import React, { useState, useEffect } from 'react'
import axiosInstance from '../components/axiosInstance/axiosInstance'
import tokenInstance from '../components/axiosInstance/tokenInstance'
import { useNavigate } from 'react-router'
import CallIcon from '@mui/icons-material/Call';


const MentorList = () => {
    const [mentorList, setMentorList] = useState([])
    const [roomId, setRoomID] = useState(null)
    const [callResponse, setCallResponse] = useState([])
    const [studentId, setStudentId] = useState(null)
    const [socket, setSocket] = useState(null);
    const navigate = useNavigate()
    





    const fetchMentorList = async () => {
        try {
            const response = await tokenInstance.get("calls/api/v1/mentors_list/");
            setMentorList(response.data)


        } catch (err) {
            console.log("api fetching error", err.message)
        }


    }

    useEffect(() => {
        fetchMentorList();
    }, []);
    const handleCall = async (roomId) => {
        if (!roomId) {
            console.error("Room ID is not set.");
            return;
        }
        try {
            const response = await tokenInstance.post("calls/api/v1/voice-call-room-join-request/", {
                room: roomId

            })
            setCallResponse(response.data)
            setStudentId(response.data.student.id)
            if (response.status === 201) {
                navigate('/callwaiting', { state: { callResponse: response.data } })
            } else {
                console.log("unexpected status code ", response.data)
            }

        } catch (err) {
            console.log("error to post call api ", err.message)

        }
    }
    useEffect(() => {
        // Fetch mentor list on initial render
        fetchMentorList();

        // Set up WebSocket connection
        const ws = new WebSocket('wss://norvelbackend.site/ws/mentor_list_refresh/mentors/');
        setSocket(ws);

        // WebSocket message listener
        ws.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            // Call fetchMentorList to refresh data
            fetchMentorList();
        };

        // Clean up WebSocket connection on component unmount
        return () => {
            if (ws) {
                ws.close();
                console.log('WebSocket connection closed');
            }
        };
    }, []); // Empty dependency array ensures this runs only once





    return (
        <div className='px-4'>
    <div className='text-2xl font-bold py-2'>Mentor List</div>
    {mentorList.length > 0 ? (
        <div className='grid grid-cols-1 md:grid-cols-4 gap-6 '>
            {mentorList.map((mentor) => (
                <div key={mentor.id} className='flex flex-col items-center bg-blue-50 py-4  rounded-md shadow-md'>
                    <div className='w-[150px] h-[200px]'>
                        <img
                            src="./mentor.jpg"
                            alt="Mentor"
                            className='w-full h-full object-cover rounded-md'
                        />
                    </div>
                    <div className='flex flex-col gap-2 mt-4 text-center'>
                        <div style={{ fontWeight: 'bold' }}>
                            {mentor.first_name} {mentor.last_name}
                        </div>
                        <div className='text-[12px] pt-sans-bold text-blue-500'>
                            Griffith College of Applied Sciences,<br /> Dublin, <span className='text-black'>Ireland</span>
                        </div>
                        <div>
                            <div className='text-[11px] pt-sans-regular'>
                                Studying MSc. Microbiology and<br />
                                Clinical Psychology
                            </div>
                            <div className='pt-4'>
                                {mentor.room ? (
                                    <div
                                        className='font-medium bg-green-600 flex items-center justify-center text-[13px] text-white px-4 py-1 rounded-md cursor-pointer'
                                        onClick={() => handleCall(mentor.room)}
                                    >
                                        <CallIcon />
                                        <span className='ml-1'>Call Now</span>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div>Loading mentors...</div>
    )}
</div>






    )
}

export default MentorList