import React, { useState, useEffect } from 'react'
import tokenInstance from '../components/axiosInstance/tokenInstance'
import { useNavigate } from 'react-router'

const RoomRequistList = () => {
    const [requestList, setRequistList] = useState([])
    const [roomId, setRoomId] = useState(null);

    const navigate = useNavigate()

    const handleCreateRoom = async () => {
        try {
            const response = await tokenInstance.post("/calls/api/v1/voice-call-room-create/")
            console.log(response.data)
            setRoomId(response.data.id)
            console.log(response.data.id, "hellooooo")
            if (response.status === 201) {
                navigate('/mentorcallwaiting', { state: { roomId: response.data } });
            }



        } catch (err) {
            console.log("error in post method ", err.message)
        }



    }

    const fetchRequestList = async () => {

        try {
            if (roomId) {
                const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${roomId}`)
                setRequistList(response.data)

            }
        } catch (err) {
            console.log("error fetching api ", err.message)
        }

    }

    useEffect(() => {
        fetchRequestList()

    }, [roomId])


    return (
        <div>
            <div>
                {requestList.map((request, index) => {
                    return (
                        <div key={index} className='flex gap-10'>
                            <div>
                                {request.student?.first_name}
                            </div>
                            <div>
                                {request.status}
                            </div>
                        </div>

                    );

                })}
            </div>
            <div className='flex items-center justify-center h-[100vh]'>
                <div
                    onClick={handleCreateRoom}
                    className="bg-green-500  text-[13px] whitespace-nowrap font-bold  text-white py-2 px-2 rounded-md cursor-pointer text-center hover:bg-green-600"
                >
                    Create room
                </div>

            </div>


        </div>
    )
}

export default RoomRequistList