


import React, { useState, useEffect } from 'react';
import CourseFilter from './CourseFilter';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axiosInstance from '../axiosInstance/axiosInstance';
import CloseIcon from '@mui/icons-material/Close';

import { AnimatePresence } from 'framer-motion';
import FilterModal from '../filters/FilterModal';
import Filters from '../filters/Filters';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation
import { Tooltip } from 'react-tooltip';


import { ReactComponent as SearchSVG } from '../../assets/Search.svg';
import { ReactComponent as SettingsSVG } from '../../assets/Filter.svg';


const Tabs = ({searchCourseQuery,setsearchCourseQuery, setSearchQuery,fetchCourses, count, searchQuery, selectedEducationType, setSelectedEducationType, educationTypeCounts, setEducationTypeCounts, fetchCountries, newMarks,
    newFeesFrom,
    newFeesTo }) => {

    const location = useLocation();
    const navigate = useNavigate();


    const [showSlider, setShowSlider] = useState(false);
    const [secondSlider, setSecondSlider] = useState(false);
    const [title, setTitle] = useState("Masters");
    const [isClosing, setIsClosing] = useState(false);
    const [feesClosing, setFeesClosing] = useState(false);
    const [showModal, setShowModal] = useState(false);
   
    const [courses, setCourses] = useState([]);



    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const country = location.state?.countryData;
    const countryName = country?.name



    const countryId = new URLSearchParams(location.search).get('countryId');



    // Extract marks and feesTo from the URL
    const searchParams = new URLSearchParams(location.search);
    const marksFromURL = searchParams.get("marks");
    const feesToFromURL = searchParams.get("feesTo");
    const removeQueryParam = (key) => {
        // Create a new URLSearchParams instance from the current search params
        const searchParams = new URLSearchParams(window.location.search);

        // Remove the query parameter from the URL
        searchParams.delete(key);

        // Update the URL without reloading the page
        navigate({ search: searchParams.toString() });

        // Trigger the appropriate API call based on the current pathname
        if (location.pathname === '/') {
            // If we're on the root path, we need to call `fetchCountries` with `feesTo` if `marks` is removed
            const marks = searchParams.get("marks");
            const feesTo = searchParams.get("feesTo");

            if (marks) {
                fetchCountries(marks); // Call fetchCountries with `marks` parameter
            } else if (feesTo) {
                fetchCountries(null, feesTo); // Call fetchCountries with `feesTo` parameter if `marks` is removed
            } else {
                fetchCountries(); // Call fetchCountries without any filters if both are empty
            }
        } else if (location.pathname === '/home') {
            // If we're on the home page, we need to call `fetchCountries` with `feesTo` if `marks` is removed
            const marks = searchParams.get("marks");
            const feesTo = searchParams.get("feesTo");

            if (marks && feesTo) {
                // If both are present, call fetchCountries with both parameters
                fetchCountries(marks, feesTo);
            } else if (marks) {
                // If only marks is present, call fetchCountries with marks
                fetchCountries(marks);
            } else if (feesTo) {
                // If only feesTo is present, call fetchCountries with feesTo
                fetchCountries(null, feesTo);
            } else {
                // If both are missing, call fetchCountries without any filters
                fetchCountries();
            }
        } else if (location.pathname.includes('/course')) {
           
            const marks = searchParams.get("marks");
            const feesTo = searchParams.get("feesTo");

            if (marks) {
                fetchCourses(marks); 
            } else if (feesTo) {
                fetchCourses(null, feesTo); 
            } else {
                fetchCourses(); 
            }
        }
    };








    // State for slider values

    const [formData, setFormData] = useState({
        marks: 50,
        // fees_from: 0,
        fees_to: 10,

    });
    const toggleFilterModal = () => {
        setShowModal(!showModal); // Toggle modal visibility
    };



    const toggleSlider = () => {
        if (showSlider) {
            setIsClosing(true); // Start the closing animation
            setTimeout(() => {
                setShowSlider(false); // Hide slider after animation completes
                setIsClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setShowSlider(true); // Open slider immediately
        }
    };

    // const toggleSlider = () => {
    //     setShowSlider(prev => !prev);
    // };
    // const toggleSecondSlider = () => {
    //     setSecondSlider(prev => !prev);
    // };
    const toggleSecondSlider = () => {
        if (secondSlider) {
            setFeesClosing(true); // Start the closing animation
            setTimeout(() => {
                setSecondSlider(false); // Hide slider after animation completes
                setFeesClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setSecondSlider(true); // Open slider immediately
        }

    }



    const handleSliderChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, marks: newValue }));


    };
    const handleSliderCommittedChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, marks: newValue }));
        setIsClosing(true);
        setTimeout(() => {
            setShowSlider(false); // Hide slider after animation completes
            setIsClosing(false); // Reset closing state
        }, 300); // Match the transition duration in CSS
    };


    // const handleFeesChange = (event, newValue) => {
    //     setFormData(prev => ({
    //         ...prev,
    //         fees_from: newValue[0],
    //         fees_to: newValue[1]
    //     }));

    // };


    const handleFeesChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            fees_to: newValue,
        }));
    };
    const handleFeesCommittedChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            fees_to: newValue,
        }));

        setFeesClosing(true);
        setTimeout(() => {
            setSecondSlider(false);
            setFeesClosing(false);
        }, 300);
    };



    const formatFees = (value) => `${value} LPA`; // Example formatting function
    useEffect(() => {
        // Check if `selectedEducationType` exists and has a name
        if (selectedEducationType && selectedEducationType.name) {
            setTitle(selectedEducationType.name); // Update title with the selected education type's name
            // Save the selectedEducationType to local storage for persistence
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            setTitle("Masters"); // Default title if no selectedEducationType
        }
    }, [selectedEducationType]); // Add `selectedEducationType` as a dependency


    



    // useEffect(() => {
    //     const debounceTimeout = setTimeout(() => {
    //         fetchCountries(formData.marks, formData.fees_from, formData.fees_to);
    //     }, 500); // Call fetchCountries 500ms after the last slider change

    //     return () => clearTimeout(debounceTimeout); // Cleanup previous timeout on change
    // }, [formData]); // Trigger only when formData changes
    // const fetchCourses = (url) => {

    //     let requestUrl = `${url}`;



    //     // Make the API request
    //     axiosInstance.get(requestUrl)
    //         .then(response => {

    //             setCourses(response.data.results); // Set the courses from response
    //             setNextPage(response.data.next); // Set the next page URL
    //             setPreviousPage(response.data.previous); // Set the previous page URL
    //         })
    //         .catch(error => {
    //             console.error('Error fetching courses:', error);
    //         });
    // };
    const storedEducationType = localStorage.getItem('selectedEducationType')
  ? JSON.parse(localStorage.getItem('selectedEducationType'))
  : null;


    useEffect(() => {
       
    const searchParams = new URLSearchParams(location.search);
    const marks = searchParams.get('marks') || ''; // Default to 33 if not found
    const feesTo = searchParams.get('feesTo') || ''; // Default to 50 if not found
    const countryId = searchParams.get('countryId') || ''; 
    console.log(countryId,"hello country id ");
    

    // Check if countryId is valid
    if (countryId && countryId !== 'null' && countryId !== 'undefined') {
        // Construct the API URL with the extracted parameters
        const apiUrl = `https://norvelbackend.site/home/api/v1/student-course-list/?country=${countryId}&limit=4&education_type=${storedEducationType.id}&marks=${marks}&fees_to=${feesTo}`;

        // Call fetchCourses with the constructed URL
        fetchCourses(apiUrl);
    } else {
        console.error('Invalid countryId:', countryId); // Log error if countryId is invalid
    }
    }, [countryId, searchCourseQuery,location.search,]);


    useEffect(() => {
        const modalShown = sessionStorage.getItem('modalShown');
        if (!modalShown) {
            setTimeout(() => {
                setShowModal(true);
                console.log("show modal ");

                sessionStorage.setItem('modalShown', 'true');
            }, 30000);
        }
    }, []);



    return (
        <div>
            <div>

                <div className=" px-4 md:px-20">
                    <div className="flex flex-col ">
                        <div className=' py-4 md:py-6 flex flex-col '>
                            {!countryName && <div className=' block md:hidden text-[18px] lg:text-[28px] pt-sans-bold font-bold'> Choose from {count} Study abroad Destinations    </div>}
                            {countryName &&
                                <div className=' block md:hidden text-[18px] lg:text-[28px] pt-sans-bold font-bold text-center'> {title}  Study Programs   {countryName && ` in  ${countryName}`} </div>}
                            <div className=' hidden md:block text-[18px] lg:text-[28px] pt-sans-bold font-bold text-center'> {title}  Study Programs   {countryName && ` in  ${countryName}`} </div>
                            <div className='hidden md:block'>
                                <CourseFilter
                                    setSelectedEducationType={setSelectedEducationType}
                                    selectedEducationType={selectedEducationType}
                                    setEducationTypeCounts={setEducationTypeCounts}
                                    educationTypeCounts={educationTypeCounts}
                                />
                            </div>
                        </div>
                        {location.pathname == '/' && (
                            <div className="flex items-center border border-gray-400 rounded-full px-6 py-2.5">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <SearchSVG />
                                </div>
                                <input
                                    type="text"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Country"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                                {/* Search Icon */}


                                {/* Filter Icon */}
                                <div className="ml-2" onClick={toggleFilterModal}>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1546_2966)">
                                            <path
                                                d="M23.9583 19.7916H21.1844C20.7333 18.2905 19.3542 17.1874 17.7083 17.1874C16.0625 17.1874 14.6844 18.2905 14.2323 19.7916H1.04165C0.46665 19.7916 0 20.2583 0 20.8333C0 21.4083 0.46665 21.8749 1.04165 21.8749H14.2323C14.6833 23.3759 16.0625 24.4791 17.7083 24.4791C19.3541 24.4791 20.7323 23.3759 21.1843 21.8749H23.9583C24.5343 21.8749 25 21.4083 25 20.8333C25 20.2583 24.5344 19.7916 23.9583 19.7916ZM17.7083 22.3958C16.8469 22.3958 16.1458 21.6947 16.1458 20.8333C16.1458 19.9718 16.8469 19.2708 17.7083 19.2708C18.5698 19.2708 19.2708 19.9718 19.2708 20.8333C19.2708 21.6947 18.5698 22.3958 17.7083 22.3958ZM23.9583 3.1249H21.1844C20.7323 1.62388 19.3542 0.520752 17.7083 0.520752C16.0625 0.520752 14.6844 1.62388 14.2323 3.1249H1.04165C0.46665 3.1249 0 3.59155 0 4.16655C0 4.74155 0.46665 5.2082 1.04165 5.2082H14.2323C14.6844 6.70928 16.0625 7.8124 17.7083 7.8124C19.3542 7.8124 20.7323 6.70928 21.1844 5.20825H23.9583C24.5344 5.20825 25 4.7416 25 4.1666C25 3.5916 24.5344 3.1249 23.9583 3.1249ZM17.7083 5.72905C16.8469 5.72905 16.1458 5.02803 16.1458 4.16655C16.1458 3.30508 16.8469 2.60405 17.7083 2.60405C18.5698 2.60405 19.2708 3.30508 19.2708 4.16655C19.2708 5.02803 18.5698 5.72905 17.7083 5.72905ZM23.9583 11.4583H10.7677C10.3156 9.95723 8.9375 8.8541 7.2917 8.8541C5.6459 8.8541 4.26772 9.95723 3.81567 11.4583H1.04165C0.46665 11.4583 0 11.9249 0 12.4999C0 13.0749 0.46665 13.5416 1.04165 13.5416H3.81562C4.26772 15.0426 5.64585 16.1457 7.29165 16.1457C8.93745 16.1457 10.3156 15.0426 10.7677 13.5416H23.9583C24.5343 13.5416 25 13.0749 25 12.4999C25 11.9249 24.5344 11.4583 23.9583 11.4583ZM7.29165 14.0624C6.43018 14.0624 5.72915 13.3614 5.72915 12.4999C5.72915 11.6384 6.43018 10.9374 7.29165 10.9374C8.15312 10.9374 8.85415 11.6384 8.85415 12.4999C8.85415 13.3614 8.15312 14.0624 7.29165 14.0624Z"
                                                fill={
                                                    (marksFromURL && marksFromURL !== "50") || (feesToFromURL && feesToFromURL !== "50")
                                                        ? "green"
                                                        : "#7C8396"
                                                }
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1546_2966">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>)

                        }
                        {location.pathname == '/home' && (
                            <div className="flex items-center border border-gray-400 rounded-full px-4 py-2.5">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <SearchSVG />
                                    {/* <img src="/search.png" alt="Search Icon" className="w-[22px] h-[22px] md:w-4 md:h-4" /> */}
                                </div>
                                <input
                                    type="search"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Country"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                                {/* Search Icon */}


                                <div className={`ml-2 ${marksFromURL || feesToFromURL ? 'text-green-500' : ''}`} data-tooltip-id="apply-filter" data-tooltip-content="Apply Filter" onClick={toggleFilterModal}>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1546_2966)">
                                            <path
                                                d="M23.9583 19.7916H21.1844C20.7333 18.2905 19.3542 17.1874 17.7083 17.1874C16.0625 17.1874 14.6844 18.2905 14.2323 19.7916H1.04165C0.46665 19.7916 0 20.2583 0 20.8333C0 21.4083 0.46665 21.8749 1.04165 21.8749H14.2323C14.6833 23.3759 16.0625 24.4791 17.7083 24.4791C19.3541 24.4791 20.7323 23.3759 21.1843 21.8749H23.9583C24.5343 21.8749 25 21.4083 25 20.8333C25 20.2583 24.5344 19.7916 23.9583 19.7916ZM17.7083 22.3958C16.8469 22.3958 16.1458 21.6947 16.1458 20.8333C16.1458 19.9718 16.8469 19.2708 17.7083 19.2708C18.5698 19.2708 19.2708 19.9718 19.2708 20.8333C19.2708 21.6947 18.5698 22.3958 17.7083 22.3958ZM23.9583 3.1249H21.1844C20.7323 1.62388 19.3542 0.520752 17.7083 0.520752C16.0625 0.520752 14.6844 1.62388 14.2323 3.1249H1.04165C0.46665 3.1249 0 3.59155 0 4.16655C0 4.74155 0.46665 5.2082 1.04165 5.2082H14.2323C14.6844 6.70928 16.0625 7.8124 17.7083 7.8124C19.3542 7.8124 20.7323 6.70928 21.1844 5.20825H23.9583C24.5344 5.20825 25 4.7416 25 4.1666C25 3.5916 24.5344 3.1249 23.9583 3.1249ZM17.7083 5.72905C16.8469 5.72905 16.1458 5.02803 16.1458 4.16655C16.1458 3.30508 16.8469 2.60405 17.7083 2.60405C18.5698 2.60405 19.2708 3.30508 19.2708 4.16655C19.2708 5.02803 18.5698 5.72905 17.7083 5.72905ZM23.9583 11.4583H10.7677C10.3156 9.95723 8.9375 8.8541 7.2917 8.8541C5.6459 8.8541 4.26772 9.95723 3.81567 11.4583H1.04165C0.46665 11.4583 0 11.9249 0 12.4999C0 13.0749 0.46665 13.5416 1.04165 13.5416H3.81562C4.26772 15.0426 5.64585 16.1457 7.29165 16.1457C8.93745 16.1457 10.3156 15.0426 10.7677 13.5416H23.9583C24.5343 13.5416 25 13.0749 25 12.4999C25 11.9249 24.5344 11.4583 23.9583 11.4583ZM7.29165 14.0624C6.43018 14.0624 5.72915 13.3614 5.72915 12.4999C5.72915 11.6384 6.43018 10.9374 7.29165 10.9374C8.15312 10.9374 8.85415 11.6384 8.85415 12.4999C8.85415 13.3614 8.15312 14.0624 7.29165 14.0624Z"
                                                fill={
                                                    (marksFromURL && marksFromURL !== "") || (feesToFromURL && feesToFromURL !== "")
                                                        ? "green"
                                                        : "#7C8396"
                                                }
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1546_2966">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>



                                </div>
                                <Tooltip id="apply-filter" place="top" />
                            </div>)

                        }




                        {/* course  */}
                        {location.pathname === "/course" && (
                            <div className="flex items-center border border-gray-400 rounded-full px-4 py-2.5">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <SearchSVG />
                                    {/* <img src="/search.png" alt="Search Icon" className="w-[22px] h-[22px] md:w-4 md:h-4" /> */}
                                </div>
                                <input
                                    type="search"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Course"
                                    value={searchCourseQuery} // Bind the input value to the searchQuery state
                                    onChange={(e) => setsearchCourseQuery(e.target.value)}
                                />

                                {/* Search Icon */}


                                {/* Filter Icon */}
                                <div className={`ml-2 ${marksFromURL || feesToFromURL ? 'text-green-500' : ''}`} data-tooltip-id="apply-filter" data-tooltip-content="Apply Filter" onClick={toggleFilterModal}>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1546_2966)">
                                            <path
                                                d="M23.9583 19.7916H21.1844C20.7333 18.2905 19.3542 17.1874 17.7083 17.1874C16.0625 17.1874 14.6844 18.2905 14.2323 19.7916H1.04165C0.46665 19.7916 0 20.2583 0 20.8333C0 21.4083 0.46665 21.8749 1.04165 21.8749H14.2323C14.6833 23.3759 16.0625 24.4791 17.7083 24.4791C19.3541 24.4791 20.7323 23.3759 21.1843 21.8749H23.9583C24.5343 21.8749 25 21.4083 25 20.8333C25 20.2583 24.5344 19.7916 23.9583 19.7916ZM17.7083 22.3958C16.8469 22.3958 16.1458 21.6947 16.1458 20.8333C16.1458 19.9718 16.8469 19.2708 17.7083 19.2708C18.5698 19.2708 19.2708 19.9718 19.2708 20.8333C19.2708 21.6947 18.5698 22.3958 17.7083 22.3958ZM23.9583 3.1249H21.1844C20.7323 1.62388 19.3542 0.520752 17.7083 0.520752C16.0625 0.520752 14.6844 1.62388 14.2323 3.1249H1.04165C0.46665 3.1249 0 3.59155 0 4.16655C0 4.74155 0.46665 5.2082 1.04165 5.2082H14.2323C14.6844 6.70928 16.0625 7.8124 17.7083 7.8124C19.3542 7.8124 20.7323 6.70928 21.1844 5.20825H23.9583C24.5344 5.20825 25 4.7416 25 4.1666C25 3.5916 24.5344 3.1249 23.9583 3.1249ZM17.7083 5.72905C16.8469 5.72905 16.1458 5.02803 16.1458 4.16655C16.1458 3.30508 16.8469 2.60405 17.7083 2.60405C18.5698 2.60405 19.2708 3.30508 19.2708 4.16655C19.2708 5.02803 18.5698 5.72905 17.7083 5.72905ZM23.9583 11.4583H10.7677C10.3156 9.95723 8.9375 8.8541 7.2917 8.8541C5.6459 8.8541 4.26772 9.95723 3.81567 11.4583H1.04165C0.46665 11.4583 0 11.9249 0 12.4999C0 13.0749 0.46665 13.5416 1.04165 13.5416H3.81562C4.26772 15.0426 5.64585 16.1457 7.29165 16.1457C8.93745 16.1457 10.3156 15.0426 10.7677 13.5416H23.9583C24.5343 13.5416 25 13.0749 25 12.4999C25 11.9249 24.5344 11.4583 23.9583 11.4583ZM7.29165 14.0624C6.43018 14.0624 5.72915 13.3614 5.72915 12.4999C5.72915 11.6384 6.43018 10.9374 7.29165 10.9374C8.15312 10.9374 8.85415 11.6384 8.85415 12.4999C8.85415 13.3614 8.15312 14.0624 7.29165 14.0624Z"
                                                fill={
                                                    (marksFromURL !== "" || feesToFromURL !== "")
                                                        ? "green"
                                                        : "#7C8396"
                                                }
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1546_2966">
                                                <rect width="25" height="25" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {/* <img src="/filter.png" alt="Filter Icon" className="w-[25px] h-[25px] md:w-4 md:h-4" /> */}
                                </div>

                                <Tooltip id="apply-filter" place="top" />
                            </div>

                        )}
                        <>
                            {(marksFromURL !== "100" || feesToFromURL !== "50") && (
                                <div className="flex gap-4 py-3">
                                    {marksFromURL && (
                                        <div className="flex items-center gap-1 text-[14px] rounded-full border border-green-500 px-4 py-1 bg-green-200 md:text-[14px] pt-sans-regular outline-none">
                                            <div>Mark {marksFromURL} %</div>
                                            <div onClick={() => removeQueryParam("marks")}>
                                                <CloseIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    )}
                                    {feesToFromURL && (
                                        <div className="flex items-center gap-1 text-[14px] bg-green-200 border border-green-500 rounded-full px-4 py-1 md:text-[14px] pt-sans-regular outline-none">
                                            <div> Fees  {feesToFromURL} LPA</div>
                                            <div onClick={() => removeQueryParam("feesTo")}>
                                                <CloseIcon style={{ fontSize: "20px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>









                        {/* <div className='flex flex-col justify-center  gap-4 pt-4 md:flex-row py-2 px-2'>
                            <div className='flex flex-col w-full justify-center gap-4  md:gap-10 md:flex-row'>
                                {/* <div className='flex flex-col lg:flex-row gap-4  '>
                                    <div
                                        className={`h-10 border border-gray-100 rounded-sm py-2 px-4 shadow-md flex justify-between items-center rounded-md cursor-pointer ${showSlider ? 'bg-green-200' : 'bg-white'
                                            }`}
                                        onClick={toggleSlider}
                                    >
                                        <div className='flex justify-between w-full gap-16 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Your Marks in Class</div>
                                            <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                        </div>
                                        <div>{showSlider ? <FiChevronUp /> : <FiChevronDown />}</div>
                                    </div>
                                    {showSlider && (
                                        <div className={` lg:w-[220px] slider-container ${isClosing ? 'closed' : ''}`}>
                                            <Box>
                                                <Slider
                                                    value={formData.marks}
                                                    onChange={handleSliderChange}
                                                    onChangeCommitted={handleSliderCommittedChange}
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"

                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: '#bfbfbf',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <div className='flex justify-between'>
                                                    <div className='pt-sans-regular text-[12px]'>0%</div>
                                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                                    <div className='pt-sans-regular text-[12px]'>100%</div>
                                                </div>
                                            </Box>
                                        </div>

                                    )}

                                </div>



                                <div className='flex flex-col lg:flex-row gap-4   '>
                                    <div className={`h-10 border border-gray-100 rounded-sm py-2 px-4 shadow-md flex justify-between items-center rounded-md cursor-pointer ${secondSlider ? 'bg-green-200' : 'bg-white'
                                        }`} onClick={toggleSecondSlider}>
                                        <div className='flex justify-between w-full gap-6 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Budget For Education</div>
                                            <div className='pt-sans-bold text-[12px]'>Up to {formData.fees_to} LPA</div>
                                        </div>
                                        <div><FiChevronDown /></div>
                                    </div>
                                    {secondSlider && (
                                        <div className={` lg:w-[220px] slider-container ${feesClosing ? 'closed' : ''}`}>
                                            <Box>
                                                <Slider
                                                    value={formData.fees_to}
                                                    onChange={handleFeesChange}
                                                    valueLabelDisplay="auto"
                                                    onChangeCommitted={handleFeesCommittedChange}
                                                    min={0}
                                                    max={50}
                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <div className='flex justify-between '>
                                                <div className='pt-sans-regular text-[12px]'>0 LPA</div>
                                                <div className='pt-sans-bold text-[12px]'>{` ${formatFees(formData.fees_to)}`}</div>
                                                <div className='pt-sans-regular text-[12px]'>50 LPA</div>
                                            </div>
                                        </div>

                                    )}


                                </div> */}



                        {/* </div>


                        </div> */}

                        <div className='flex flex-col justify-center  '>
                            {location.pathname === "/" && (
                                <div className='text-black pt-sans-bold text-[16px] pb-2 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Major Study Destinations
                                </div>
                            )}
                            {location.pathname === "/home" && (
                                <div className='text-black pt-sans-bold text-[16px] pb-2 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Major Study Destinations
                                </div>
                            )}



                            {location.pathname === "/course" && (
                                <div className='text-black pt-sans-bold text-[16px] pb-2 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Open Programs
                                </div>

                            )}

                        </div>

                    </div>
                </div>
            </div>
            <AnimatePresence>
                {
                    showModal && (
                        <FilterModal setShowModal={setShowModal} fetchCourses={fetchCourses} fetchCountries={fetchCountries} marks={newMarks} feesTo={newFeesTo} />

                    )
                }
            </AnimatePresence>
        </div>
    );
};

export default Tabs;
