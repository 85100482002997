// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router';
// import tokenInstance from '../components/axiosInstance/tokenInstance';
// import { createClient } from 'agora-rtc-sdk-ng';
// import AgoraRTC from 'agora-rtc-sdk-ng';

// const MentorCall = () => {
//     const [mentorCallResponse, setMentorCallResponse] = useState([]);
//     const [mentorCallRequst, setMentorCallRequst] = useState([]);
//     const [mentorId, setMentorId] = useState();
//     const [socket, setSocket] = useState(null);
//     const [agoraToken, setAgoraToken] = useState(null);
//     const [client, setClient] = useState(null);
//     const [isJoined, setIsJoined] = useState(false);
//     const [channelName, setChannelName] = useState(null);
//     const [uid, setUid] = useState(null);

//     const location = useLocation();
//     const mentorResponse = location.state?.roomId;
//     const roomId = mentorResponse?.id;

//     const appId = "f9396bbbaad64783abd978a28e727ce2";

//     useEffect(() => {
//         if (mentorResponse) {
//             setMentorId(mentorResponse.mentor.id);
//         } else {
//             console.error("Room ID is not provided.");
//         }
//     }, [mentorResponse]);

//     const fetchRequestList = async () => {
//         try {
//             if (roomId) {
//                 const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${roomId}`);
//                 setMentorCallRequst(response.data);
//                 console.log(response.data, "hello request data");
//             }
//         } catch (err) {
//             console.log("error fetching api ", err.message);
//         }
//     };

//     useEffect(() => {
//         if (roomId) {
//             fetchRequestList();
//         }
//     }, [roomId]); // Add roomId as a dependency

//     const callReject = async (requestId) => {
//         try {
//             const response = await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
//                 "status": "Rejected"
//             });
//             console.log(response.data);
//         } catch (err) {
//             console.log("error in update status api", err.message);
//         }
//     };

//     const userInfo = JSON.parse(localStorage.getItem('user_info'));
//     const userId = userInfo?.id;

//     useEffect(() => {
//         if (userId) {
//             const wsUrl = `wss://norvelbackend.site/ws/voice-call-token/voice_call_room_${userId}/`;
//             const newSocket = new WebSocket(wsUrl);
//             setSocket(newSocket);

//             newSocket.onopen = () => {
//                 console.log("WebSocket connection established.");
//             };

//             newSocket.onmessage = (event) => {
//                 try {
//                     const message = JSON.parse(event.data);
//                     if (message.type === "voice_call_websocket_receiver") {
//                         setAgoraToken(message.event.token);
//                         setUid(message.event.uid);
//                         setChannelName(message.event.channel_name);

//                         if (message.event.remote_uid && client) {
//                             const remoteUid = message.event.remote_uid;
//                             client.subscribe(remoteUid); // Use client from state
//                             const remoteVideoContainer = document.getElementById("remote-video");
//                             remoteUid.play(remoteVideoContainer); // Assuming remoteUid is a valid Agora user instance
//                         }
//                     }
//                 } catch (error) {
//                     console.error("Error parsing WebSocket message:", error);
//                 }
//             };

//             newSocket.onerror = (error) => {
//                 console.error("WebSocket error:", error);
//             };

//             newSocket.onclose = (event) => {
//                 console.log("WebSocket connection closed:", event);
//             };

//             return () => {
//                 newSocket.close();
//             };
//         }
//     }, [userId]);

//     const joinAgoraChannel = async () => {
//         if (!agoraToken || !channelName || !uid) {
//             console.error('Missing Agora token, channel name, or UID.');
//             return;
//         }

//         try {
//             const agoraClient = createClient({ mode: 'rtc', codec: 'vp8' });
//             setClient(agoraClient);

//              // Create only the microphone track
//              const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

//              // Join the Agora channel
//              await agoraClient.join(appId, channelName, agoraToken, uid);
//              await agoraClient.publish([localAudioTrack]);



//             localAudioTrack.play(); 

//             setIsJoined(true);
//             console.log(`Joined Agora channel: ${channelName}`);
//         } catch (error) {
//             console.error('Error joining Agora channel:', error);
//         }
//     };


//     const leaveAgoraChannel = async () => {
//         if (client) {
//             try {
//                 await client.leave();
//                 setIsJoined(false);
//                 console.log("Left Agora channel.");
//             } catch (error) {
//                 console.error("Error leaving Agora channel:", error);
//             }
//         }
//     };

//     const statusUpdate = async (requestId) => {
//         try {
//             const response = await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
//                 "status": "Accepted"
//             });
//             console.log(response.data);
//             await joinAgoraChannel();
//         } catch (err) {
//             console.log("error in update status api", err.message);
//         }
//     };

//     return (
//         <div>

//             {/* {isJoined ? (
//                 <button onClick={leaveAgoraChannel} className="bg-red-500 text-white px-4 py-2 rounded">
//                     Leave Call
//                 </button>
//             ) : (
//                 <button onClick={joinAgoraChannel} className="bg-green-500 text-white px-4 py-2 rounded">
//                     Join Call
//                 </button>
//             )} */}

//             {/* Add a div to show the local video */}
//             {/* <div id="local-video" style={{ width: "300px", height: "300px", backgroundColor: "black" }}></div> */}

//             {/* You can also add a section for remote users */}
//             <div id="remote-video" style={{ display: "flex", flexDirection: "row" }}></div>

//             <div className='font-bold text-md py-4 px-4'>Request List</div>
//             {mentorCallRequst.map((request, index) => {
//                 return (
//                     <div key={index} className="flex gap-8 px-4">
//                         <div className='font-medium'>{request.student?.first_name}</div>
//                         <div className='bg-green-400 text-white text-[12px] py-1 px-4 rounded-md cursor-pointer' onClick={() => statusUpdate(request.id)}>Call</div>
//                         <div className='bg-red-400 text-white text-[12px] py-1 px-4 rounded-md cursor-pointer' onClick={() => callReject(request.id)}>Reject</div>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// };

// export default MentorCall;
import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router';
import tokenInstance from '../components/axiosInstance/tokenInstance';
import { createClient } from 'agora-rtc-sdk-ng';
import AgoraRTC from 'agora-rtc-sdk-ng';

const MentorCall = () => {
    const [mentorCallResponse, setMentorCallResponse] = useState([]);
    const [mentorCallRequst, setMentorCallRequst] = useState([]);
    const [mentorId, setMentorId] = useState();
    const [socket, setSocket] = useState(null);
    const [agoraToken, setAgoraToken] = useState(null);
    const [client, setClient] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [channelName, setChannelName] = useState(null);
    const [uid, setUid] = useState(null);
    const [isReadyToJoin, setIsReadyToJoin] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [requestSocket, setRequestSocket] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null); // Track local audio
    const navigate = useNavigate();

    const location = useLocation();
    const mentorResponse = location.state?.roomId;
    const roomId = mentorResponse?.id;

    const appId = "f9396bbbaad64783abd978a28e727ce2";

    useEffect(() => {
        if (mentorResponse) {
            setMentorId(mentorResponse.mentor.id);
        } else {
            console.error("Room ID is not provided.");
        }
    }, [mentorResponse]);

    const fetchRequestList = async () => {
        try {
            if (roomId) {
                const response = await tokenInstance.get(`calls/api/v1/voice-call-room-request-list/?room_id=${roomId}`);
                setMentorCallRequst(response.data);
            }
        } catch (err) {
            console.error("Error fetching request list:", err.message);
        }
    };

    useEffect(() => {
        if (roomId) {
            fetchRequestList();
        }
    }, [roomId]);

    const callReject = async (requestId) => {
        try {
            await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
                status: "Rejected",
            });
        } catch (err) {
            console.error("Error rejecting call request:", err.message);
        }
    };

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const userId = userInfo?.id;

    useEffect(() => {
        if (userId) {
            const wsUrl = `wss://norvelbackend.site/ws/voice-call-token/voice_call_room_${userId}/`;
            const newSocket = new WebSocket(wsUrl);
            setSocket(newSocket);

            newSocket.onopen = () => {
                console.log("WebSocket connection established.");
            };

            newSocket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    if (message.type === "voice_call_websocket_receiver") {
                        setAgoraToken(message.event.token);
                        setUid(message.event.uid);
                        setChannelName(message.event.channel_name);
                        setIsReadyToJoin(true); // State to indicate Agora parameters are ready
                    }
                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            newSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            newSocket.onclose = (event) => {
                console.log("WebSocket connection closed:", event);
            };

            return () => {
                newSocket.close();
            };
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            // WebSocket for voice call requests
            const wsRequestUrl = `wss://norvelbackend.site/ws/voice-call-requests/voice_call_requests_${userId}/`;
            const newRequestSocket = new WebSocket(wsRequestUrl);
            setRequestSocket(newRequestSocket);

            newRequestSocket.onopen = () => {
                console.log('Request WebSocket connection established.');
            };

            newRequestSocket.onmessage = () => {
                console.log('Request WebSocket message received, fetching request list.');
                fetchRequestList();
            };

            newRequestSocket.onerror = (error) => {
                console.error('Request WebSocket error:', error);
            };

            newRequestSocket.onclose = (event) => {
                console.log('Request WebSocket connection closed:', event);
            };

            // Clean up on unmount
            return () => {
                newRequestSocket.close();
            };
        }
    }, [userId]);

    const joinAgoraChannelMentor = async () => {
        if (!agoraToken || !channelName || !uid) {
            console.error("Missing Agora token, channel name, or UID.");
            return;
        }

        try {
            const agoraClient = createClient({ mode: 'rtc', codec: 'vp8' });
            setClient(agoraClient);

            // Create and publish local audio track
            const track = await AgoraRTC.createMicrophoneAudioTrack();
            setLocalAudioTrack(track);
            await agoraClient.join(appId, channelName, agoraToken, uid);
            await agoraClient.publish([track]);
            console.log("Mentor audio track published.");

            // Handle remote user subscriptions
            agoraClient.on("user-published", async (user, mediaType) => {
                await agoraClient.subscribe(user, mediaType);
                console.log("Subscribed to remote user:", user.uid);

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                    console.log("Playing remote audio from user:", user.uid);
                }
            });

            agoraClient.on("user-unpublished", (user) => {
                console.log("Remote user unpublished:", user.uid);
            });

            setIsJoined(true);
        } catch (error) {
            console.error("Error joining Agora channel (Mentor):", error);
        }
    };

    const toggleMute = () => {
        if (localAudioTrack) {
            if (isMuted) {
                localAudioTrack.setEnabled(true); // Unmute
                console.log("Microphone unmuted.");
            } else {
                localAudioTrack.setEnabled(false); // Mute
                console.log("Microphone muted.");
            }
            setIsMuted(!isMuted);
        }
    };
    const statusUpdate = async (requestId) => {
        try {
            const response = await tokenInstance.put(`calls/api/v1/voice-call-room-request-update/${requestId}/`, {
                "status": "Accepted"
            });
            console.log(response.data);

        } catch (err) {
            console.log("error in update status api", err.message);
        }
    };

    useEffect(() => {
        if (isReadyToJoin && agoraToken && channelName && uid) {
            joinAgoraChannelMentor();
        }
    }, [isReadyToJoin, agoraToken, channelName, uid]);
    const endCall = async () => {
        if (client) {
            try {
                await client.leave();
                client.remoteUsers.forEach((user) => {
                    if (user.audioTrack) {
                        user.audioTrack.stop();
                        user.audioTrack.close();
                    }
                });
                if (localAudioTrack) {
                    localAudioTrack.stop();
                    localAudioTrack.close();
                }
                setClient(null);
                setLocalAudioTrack(null);
                setIsJoined(false);
                console.log("Call ended successfully.");
                navigate("/room");
            } catch (error) {
                console.error("Error ending the call:", error);
            }
        }
    };


    return (
        <div className='flex flex-col items-center justify-center py-10'>
            <div id="remote-video" style={{ display: "flex", flexDirection: "row" }}></div>
            <div className='flex flex-col items-center  w-[500px] bg-gray-400 rounded-md py-4  px-4'>
                <div className='flex flex-row gap-10'>
                    <div className='flex items-center flex-col '>
                        <div className='w-[70px] h-[70px]'>
                            <img src="/mentor1.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                        </div>
                        <div className='text-[11px] pt-sans-regular'>student</div>
                    </div>

                    <div>
                        <img src="/icon.png" className='w-[100%] h-[100%] object-contain' />
                    </div>
                    <div className='flex items-center flex-col '>
                        <div className='w-[70px] h-[70px]'>
                            <img src="/stu1.jpg" className='w-[100%] h-[100%] object-cover rounded-full' />
                        </div>
                        <div className='text-[11px] pt-sans-regular'>Mentor</div>
                    </div>

                </div>
                {isJoined && (
                    <div className='flex gap-8'>
                        <div>
                            <button
                                onClick={toggleMute}
                                className={`mt-4 py-1 mx-4 px-4 rounded ${isMuted ? 'bg-red-500' : 'bg-green-500'} text-[12px] text-white`}
                            >
                                {isMuted ? 'Unmute' : 'Mute'}
                            </button>
                        </div>
                        <div>
                        <button
                            onClick={endCall}
                            className="mt-4 py-1 px-4 rounded bg-red-600 text-[12px] text-white"
                        >
                            End Call
                        </button>
                        </div>

                    </div>


                )}





            </div>


            <div className="font-bold text-md py-4 px-4">Request List</div>
            {mentorCallRequst.map((request, index) => (
                <div key={index} className="flex gap-8 px-4">
                    <div className="font-medium">{request.student?.first_name}</div>
                    <div
                        className="bg-green-400 text-white text-[12px] py-1 px-4 rounded-md cursor-pointer"
                        onClick={() => statusUpdate(request.id)}
                    >
                        Call
                    </div>
                    <div
                        className="bg-red-400 text-white text-[12px] py-1 px-4 rounded-md cursor-pointer"
                        onClick={() => callReject(request.id)}
                    >
                        Reject
                    </div>
                </div>
            ))}
            {/* {isJoined && (
                <button
                    onClick={toggleMute}
                    className={`mt-4 py-1 mx-4 px-4 rounded ${isMuted ? 'bg-red-500' : 'bg-green-500'} text-[12px] text-white`}
                >
                    {isMuted ? 'Unmute' : 'Mute'}
                </button>
            )} */}

            {/* Mute/Unmute Button */}

        </div>
    );
};

export default MentorCall;












