import React, { useEffect, useState } from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'
import axiosInstance from '../components/axiosInstance/axiosInstance'

import Courses from '../components/courses/Courses'
import Footer from '../components/footer/Footer'
import { DropdownProvider } from '../components/DropdownContext/DropdownContext'
import CourseTabs from '../components/tabs/CourseTabs'
import CourseFilter from '../components/tabs/CourseFilter'
import { useLocation } from 'react-router-dom';
import Tabs from '../components/tabs/Tabs'


const Course = () => {
    const [educationTypeCounts, setEducationTypeCounts] = useState([]);
    const [selectedEducationType, setSelectedEducationType] = useState('');
    const [searchCourseQuery, setsearchCourseQuery] = useState('');
    const [courses, setCourses] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [count, setCount] = useState(0)
    const [universities, setUniversities] = useState([]);
    const [nextsPage, setNextsPage] = useState(null);
    const [universitiesCount, setUniversitiesCount] = useState(0)
    const [prevPage, setPrevPage] = useState(null);
    const [courseLoading,SetcourseLoading]=useState(true);






    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const marks = searchParams.get('marks');
    const feesFrom = searchParams.get('feesFrom');
    const feesTo = searchParams.get('feesTo');
    const countryId =searchParams.get('countryId');



    const countryData = location.state?.countryData || null;
   
   
    // const fetchCountries = async (url) => {

    //     try {
    //         const response = await axiosInstance.get(`${url}`);
    //         setEducationTypeCounts(response.data.education_type_count);
    //     } catch (error) {
    //         console.error('Error fetching country data:', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchCountries(`home/api/v1/country-list/?limit=9&`);
    // }, [ ]);


    // useEffect(() => {
    //     fetchCountries(`home/api/v1/country-list/?limit=9`);
    //     const storedEducationType = localStorage.getItem('selectedEducationType');
    //     if (storedEducationType) {
    //         try {
    //             setSelectedEducationType(JSON.parse(storedEducationType));
    //         } catch (error) {
    //             console.error('Error parsing selectedEducationType from localStorage:', error);
    //             localStorage.removeItem('selectedEducationType');
    //         }
    //     }
    // }, []);





    // const fetchCountries = async (url) => {
    //     try {
    //        // Debugging
    //         const response = await axiosInstance.get(url);
    //         setEducationTypeCounts(response.data.education_type_count);
    //     } catch (error) {
    //         console.error("Error fetching country data:", error);
    //     }
    // };

    
    
    useEffect(() => {
       
        const storedEducationType = localStorage.getItem("selectedEducationType");
        if (storedEducationType) {
            try {
                setSelectedEducationType(JSON.parse(storedEducationType));
            } catch (error) {
                console.error("Error parsing selectedEducationType from localStorage:", error);
                localStorage.removeItem("selectedEducationType");
            }
        }
    }, []);
    
















    useEffect(() => {
        if (selectedEducationType) {
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            localStorage.removeItem('selectedEducationType');
        }
    }, [selectedEducationType]);

    const fetchCourses = (url) => {
        SetcourseLoading(true);

        let requestUrl = `${url}`;

      
       if (selectedEducationType?.id) {
            requestUrl += `&education_type=${selectedEducationType.id}`;
        }

       
        if (searchCourseQuery) {
            requestUrl += `&search=${searchCourseQuery}`;
        }

        
        if (marks) {
            requestUrl += `&marks=${marks}`;
        }

       
        if (feesFrom) {
            requestUrl += `&fees_from=${feesFrom}`;
        }

      
        if (feesTo) {
            requestUrl += `&fees_to=${feesTo}`;
        }

        
        axiosInstance.get(requestUrl)
            .then(response => {

                setCount(response.data.count);
                setCourses(response.data.results); // Set the courses from response
                setNextPage(response.data.next); // Set the next page URL
                setPreviousPage(response.data.previous); // Set the previous page URL
                SetcourseLoading(false);
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
                
            }).finally(()=>{
                SetcourseLoading(false);
            })
    };

    useEffect(() => {
        if (countryId && selectedEducationType?.id) {
            fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
        }
    }, [countryId, selectedEducationType, searchCourseQuery, marks, feesTo]);




    // const fetchUniversities = (url) => {
    //     let requestUrl = `${url}`;


    //     if (selectedEducationType && selectedEducationType.id) {
    //         requestUrl += `&education_type=${selectedEducationType.id}`;
    //     }

    //     if (searchQuery) {
    //         requestUrl += `&search=${searchQuery}`;
    //     }

    //     if (marks) {
    //         requestUrl += `&marks=${marks}`;
    //     }
    //     if (feesFrom) {
    //         requestUrl += `&fees_from=${feesFrom}`;
    //     }
    //     if (feesTo) {
    //         requestUrl += `&fees_to=${feesTo}`;
    //     }

    //     axiosInstance.get(requestUrl)
    //         .then(response => {
    //             setUniversitiesCount(response.data.count);
    //             setUniversities(response.data.results);
    //             setNextsPage(response.data.next);
    //             setPrevPage(response.data.previous);

    //         })

    //         .catch(error => {
    //             console.error("There was an error fetching the university data!", error);

    //         })
    // };

    // useEffect(() => {
    //     if (countryId) {
    //         fetchUniversities(`home/api/v1/student-university-list/?country=${countryId}&limit=4`);
    //     }

    // }, [countryId, selectedEducationType, searchQuery, marks, feesFrom, feesTo]);
  




    return (
        <div >
            <DropdownProvider>
                <Header />
                <div className='md:hidden'>
                    <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />
                </div>


                <Center setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} />
                {/* <div className='block md:hidden'>
                    <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />

                </div> */}
                <Tabs fetchCourses={fetchCourses}  count={count}  setEducationTypeCounts={setEducationTypeCounts}  educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} searchCourseQuery={searchCourseQuery} setsearchCourseQuery={setsearchCourseQuery}

                  


              
                />

                {/* <CourseTabs searchQuery={searchQuery} setSearchQuery={setSearchQuery} marks={marks}
                    feesFrom={feesFrom}
                    feesTo={feesTo} /> */}
                <Courses educationTypeCounts={educationTypeCounts} setEducationTypeCounts={setEducationTypeCounts} selectedEducationType={selectedEducationType} setSelectedEducationType={setSelectedEducationType} 
                    marks={marks}
                  
                    feesFrom={feesFrom}
                    courseLoading={courseLoading}
                    feesTo={feesTo}
                    courses={courses}
                    count={count}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    countryData={countryData}
                    fetchCourses={fetchCourses}
                 

                    universitiesCount={universitiesCount} />
                <Footer />


            </DropdownProvider>





        </div>
    )
}

export default Course