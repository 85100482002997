
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home';

import { useLocation } from "react-router-dom";
import Countrycourse from './pages/Countrycourse';
import University from './pages/University';
import { UniversityDetails } from './pages/UniversityDetails';
import BasicDetails from './components/Leades/BasicDetails';
import './App.css';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';

import { CLIENT_ID } from './config/config';
import axiosInstance from './components/axiosInstance/axiosInstance';



import OAuthRedirectHandler from './components/authetication/OAuthRedirectHandler';
import NotFound from './components/errorPage/NotFound';
import Courses from './components/courses/Courses';
import Course from './pages/Course';
import AdmissionPartner from './components/admission/AdmissionPartner';
import AgencyPartner from './components/admission/AgencyPartner';
import LandingPage from './pages/LandingPage';
import { DropdownProvider } from './components/DropdownContext/DropdownContext';
import MentorList from './StudentConnect/MentorList';
import RoomRequistList from './StudentConnect/RoomRequistList';
import CallWaiting from './StudentConnect/CallWaiting';
import MentorCall from './StudentConnect/MentorCall';







function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  // useGoogleOneTapLogin({
  //   onSuccess: (response) => {
  //     console.log("Google One-Tap Success:", response);
  //     const { credential } = response; // Extract the credential token
  //     if (credential) {
  //       handleGoogleLogin(credential); // Call your function with the token
  //     } else {
  //       console.error("No credential received from Google One-Tap.");
  //     }
  //   },
  //   onFailure: (error) => {
  //     console.error("Google One-Tap Error:", error);
  //     alert("Google One-Tap Login failed. Please try again.");
  //   },
  //   googleAccountConfigs: {
  //     client_id: CLIENT_ID,
  //     context: "signin", // Optional: Can be 'signin', 'signup', or 'use'
  //   },
  // });
  
  // const handleGoogleLogin = async (code) => {
  //   try {
  //     // Step 1: Call the first API to exchange the code for a token
  //     const tokenResponse = await axiosInstance.get(
  //       `account/api/v1/auth/google/callback/?code=${code}&type=dev`
  //     );
  //     const { access_token } = tokenResponse.data;

  //     // Step 2: Call the second API with the access token
  //     const loginResponse = await axiosInstance.post('account/api/v1/google_login/', {
  //       access_token,
  //     });

  //     // Save the authenticated user data
  //     setUser(loginResponse.data);
  //     console.log('Login successful:', loginResponse.data);
  //   } catch (error) {
  //     console.error('Error during API calls:', error);
  //     setError('An error occurred while logging in. Please try again.');
  //   }
  // };

  

  

  // useEffect(() => {
  //   const handler = (e) => {
  //     e.preventDefault();
  //     setDeferredPrompt(e);
  //     setIsInstallButtonVisible(true);
  //   };

  //   window.addEventListener('beforeinstallprompt', handler);

  //   return () => {
  //     window.removeEventListener('beforeinstallprompt', handler);
  //   };
  // }, []);

 

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault(); // Prevent the browser's mini-infobar from showing
      setDeferredPrompt(e); // Save the event for later use

      // Automatically show the install prompt after 3 seconds (or immediately)
      setTimeout(() => {
        if (deferredPrompt) {
          deferredPrompt.prompt(); // Trigger the install prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null); // Clear the saved event
          });
        }
      }, 3000); // Delay in milliseconds before showing the prompt (optional)
    };

    // Add event listener for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      // Cleanup event listener when the component is unmounted
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, [deferredPrompt]);
  
  

  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); 
    }, [location.pathname]); 

    return null; 
};

  return (
    <>
    <DropdownProvider>
    
   
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/home" element={<Home />} />
          <Route path="/country" element={<Countrycourse />} />
          <Route path="/universities" element={<University />} />
          <Route path="/course" element={<Course/>}/>
        
          <Route path="/studyabroad-enquiryform" element={<BasicDetails />} />
          <Route path="/university/:universityId" element={<UniversityDetails />} />
          <Route path="/admission" element= {<AgencyPartner/>}/>
          <Route path="/mentor" element= {<MentorList/>}/>
          <Route path="/room" element= {<RoomRequistList/>}/>
          <Route path="/callwaiting" element= {<CallWaiting/>}/>
          <Route path="/mentorcallwaiting" element= {<MentorCall/>}/>
         
          
        

          
          <Route path="/error" element= {<NotFound/>}/>
          <Route path="/auth/google/callback" element={<OAuthRedirectHandler />} />
        

        </Routes>
        {/* {isInstallButtonVisible && (
          <button className='hidden' onClick={handleInstallClick}>Install App</button>
        )} */}
      </Router>
      </DropdownProvider>
      </>
   
  );
}

export default App;
